// This script is referenced by the embed code's script tag.
// Its job is simply to bootstrap the full visualization embed
// library.
//
// PLEASE NOTE that this script will be included multiple times
// in a single page if there is more than one embedded visualization.
// Payload size issues will be multiplied accordingly.
// The script MUST be idempotent.

import { mainLibrarySrc, mainLibraryFilename } from './paths';

window.socrata = window.socrata || {};

// Load the main package if needed.
if (!document.querySelector(`script[src*="${mainLibraryFilename}"]`)) {
  var loaderScriptTag = document.querySelector('script[data-socrata-domain]');
  // This is required to localize the viz, but unfortunately doesn't allow for
  // multiple embeds in different languages. Does anyone require that?
  if (loaderScriptTag && loaderScriptTag.dataset.locale) {
    window.socrata.locale = loaderScriptTag.dataset.locale;
  }

  var socrataDomain = loaderScriptTag ? loaderScriptTag.getAttribute('data-socrata-domain') : null;

  var scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.async = true;
  scriptTag.charset = 'UTF-8'; // Important, non-UTF8 sites won't autodetect encoding properly.
  scriptTag.src = mainLibrarySrc(socrataDomain);
  document.head.appendChild(scriptTag);
  // Duplicates CSS Variables at root defined in visualization.scss
  document.head.insertAdjacentHTML('beforeend', '<style>\
    :root {\
      --socrata-viz-title-description-toolbar-height:70px;\
      --socrata-viz-title-toolbar-height: 48px;\
      --socrata-viz-description-toolbar-height: var(--socrata-viz-title-toolbar-height);\
      --socrata-viz-footer-height: 34px;\
      --socrata-viz-view-source-data-width: 48px;\
      --socrata-viz-export-source-data-width: 48px;\
      --socrata-viz-filter-button-width: 48px;\
      --socrata-viz-drilldown-height: 30px;\
      --forge-border-radius:4px;\
    }</style>');
  // Once the script loads, it will automatically call hydrateEmbeds.
} else {
  // If script already loaded, make sure it has rendered all embeds.
  // This covers the dynamic addition of embeds (say, a JS carousel or
  // XHR-driven content loading).
  // hydrateEmbeds is idempotent, so it's safe to call whenever.
  if (
    window.socrata &&
    window.socrata.visualizationEmbed &&
    window.socrata.visualizationEmbed.hydrateEmbeds) {
    window.socrata.visualizationEmbed.hydrateEmbeds();
  }
}

